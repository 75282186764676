import {getCookie} from "../utils/cookie"
let locale=getCookie("locale");



// // 获取浏览器默认语言
// export const getBrowserLang = function() {
//   let browserLang = navigator.language ? navigator.language: navigator.browserLanguage;
//   let defaultBrowserLang = '';
//     if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
//         defaultBrowserLang = 'zh'
//     } else {
//         defaultBrowserLang = 'en'
//     }
//     return defaultBrowserLang
// };

// if(locale==""){
//   //根据域名
//   locale="ja";
// }


if(locale==""){
  
  let browserLang = navigator.language ? navigator.language: navigator.browserLanguage;
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    locale='zh_CN';
  }
  else{
    locale=browserLang;
  }
}

$(function(){
  //alert(locale)
  $('html').attr("lang",locale)
})
export default locale 


// 删除cookie
export function getTitle(locale,key){
  let titleName=[{
    "zh_CN":{
      "Login":"登录",
      "Home":"电站首页",
      "DeviceReport":"设备报表",
      "DeviceAnalyse":"设备数据分析",
      "PsReport":"电站报表",
      "Alarm":"报警历史",
      "Authority":"权限管理",
      "Active":"激活页面",
      "Page404":"页面404错误",
      "Timeout":"页面超时",
      "Report":"报表中心",
      "PsInfo":"电站信息"
    },
    "en":{
      "Login":"Login",
      "Home":"PV Home",
      "DeviceReport":"Device Chart",
      "DeviceAnalyse":"Device Analysis",
      "PsReport":"Plant Chart",
      "Alarm":"Alarm History",
      "Authority":"Role Set",
      "Active":"Active Page",
      "Page404":"Page404",
      "Timeout":"Timeout",
      "Report":"Report Center",
      "PsInfo":"Edit Plant Info"
    },   
    "ja":{
      "Login":"ログイン",
      "Home":"発電所ホーム",
      "DeviceReport":"デバイスレポート",
      "DeviceAnalyse":"設備データ比較",
      "PsReport":"発電所レポート",
      "Alarm":"アラート履歴",
      "Authority":"発電所紐づけ",
      "Active":"アクティブなページ",
      "Page404":"404ページ",
      "Timeout":"タイムアウトページ",
      "Report":"レポートセンター",
      "PsInfo":"発電所情報"
    },
  }]

  if(locale != undefined && locale != ""){
    return titleName[0][locale][key]
  }else{
    return titleName[0]["ja"][key]
  }
}