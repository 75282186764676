/*
* Admin Layout (I-Health)
* @requires:jquery: 3.6.0 or later
*/

if (typeof jQuery === "undefined") {
    throw new Error("jQuery plugins need to be before this file");
}

// $(function () {
//     $("#cardArea").cardArea();
    
// });

$(function() {
    "use strict";
    //  移动端菜单触发按钮
    $('.mobile-button').on('click', function() {
        $('.sidebar').toggleClass('MenuOpen');
    });  

    // 手机屏幕时的菜单触发
    $('.btn-toggle-offcanvas').on('click', function () {
        $('body').toggleClass('offcanvas-active');
    }); 
    
    $('.menu-toggle').on('click', function () {
        $('.sidebar').toggleClass('open');
        $('.open').removeClass('sidebar-mini');
    });

    // layout a sidebar mini version
    $('#main-menu .m-min').on('click', function () {
        $('.sidebar').toggleClass('sidebar-mini');
        $('.sidebar-mini').removeClass('open');
        $('#navbar-content').toggleClass('nav-content-bigger');
        $('#main-content').toggleClass('main-content-bigger');
    });


     // google font setting
/*      $('.font_setting input:radio').on('click', function ()  {
		var others = $("[name='" + this.name + "']").map(function () {
			return this.value
		}).get().join(" ")
		console.log(others)
		$('body').removeClass(others).addClass(this.value)
    });
*/

    // cSidebar overflow daynamic height
    
    overFlowDynamic();

    $(window).resize(function(){
        overFlowDynamic();
    });

    function overFlowDynamic(){ 
        var sideheight=$(".sidebar.sidebar-mini").height() + 48;
        
        if(sideheight <= 760) {  
            $(".sidebar.sidebar-mini").css( "overflow", "scroll");  
        }
        else{
            $(".sidebar.sidebar-mini").css( "overflow", "visible"); 
        }
    }

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
    
    //Dropdown scroll hide using table responsive
    $('.table-responsive').on('show.bs.dropdown', function () {
        $('.table-responsive').css( "overflow", "inherit" );
    });
   
    $('.table-responsive').on('hide.bs.dropdown', function () {
            $('.table-responsive').css( "overflow", "auto" );
    })
});