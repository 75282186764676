//集中式存储管理应用的所有组件的状态

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
const state={
  psId:null,
  buildDate:null,
  mainBig:false,
  languageId:null,
  psTypeId:null,
  isload:false,
}
const mutations={
  changePsId(psid){
    state.psId=psid;
  },
  changePsTypeId(psTypeId){
    state.psTypeId=psTypeId;
  },
  changeMain(isBig){
    state.mainBig=isBig;
  },
  changeLanguage(languageId){
    state.languageId=languageId
  },
  changeIsload(isload){
    state.isload=isload
  }
}
export default new Vuex.Store({
  state,
  mutations,
})