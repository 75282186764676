import Vue from 'vue'
import { Checkbox,CheckboxGroup,CheckboxButton,RadioGroup,RadioButton,Cascader,Alert,Upload,Badge,Dropdown,DropdownMenu,DropdownItem,Col,Pagination,Row,Tabs,Tag,TabPane,Tooltip,Card,Switch,Collapse,CollapseItem,Loading,Table,TableColumn,DatePicker,Select,Option,Button,Form,FormItem,Input,Message,Container,Header,Aside,Main,Menu,Submenu,MenuItemGroup,MenuItem} from 'element-ui'

Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(CheckboxButton)

Vue.use(RadioGroup)
Vue.use(RadioButton)

Vue.use(Cascader)
Vue.use(Alert)
Vue.use(Upload)
Vue.use(Badge)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tooltip) //鼠标跟随提示语
Vue.use(Card)
Vue.use(Switch)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Loading)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(DatePicker)
Vue.use(Select)
Vue.use(Option)
Vue.use(Tag)

Vue.use(Button)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Container)
Vue.use(Header)
Vue.use(Aside)
Vue.use(Main)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Row)
Vue.use(Pagination)
Vue.prototype.$message=Message;