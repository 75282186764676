import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../components/Login.vue'
// import Home from '../components/Home.vue'
// import Welcome from '../components/Welcome.vue'
// import Users from '../components/user/Users.vue'
// import Login from '../view/Login.vue' //登录
// import Master from '../components/Master.vue' //弹框集中营
// import Home from '../view/Home.vue' //首页
//import DeviceReport from '../view/History.vue' //设备报表
//import PsReport from '../view/PsChart.vue' //电站报表
//import DeviceAnalyse from '../view/DeviceChart.vue' //设备对比
//import Authority from '../view/Authority.vue' //权限管理
//import Alarm from '../view/Alarm.vue' //故障历史

//import Active from '../view/Active.vue' //激活反馈
//import Page404 from '../view/404.vue' //404反馈
//import Timeout from '../view/TimeOut.vue' //超时反馈
import  i18n  from 'element-ui/lib/locale'
import { getTitle } from "@/utils/locale";

Vue.use(VueRouter)




const routes = [
  // 默认页
  {
    path: '/',
    redirect: 'login',
    meta: {
      title: 'Login'
    }
  },
  // 登录页
  {
    path: '/login',
    name: 'Login',
    //component: Login,
    component:(resolve)=>require(['../view/Login.vue'],resolve),
    meta: {
      title: 'Login'
    }

  },
  // 首页
  {
    path: '/master',
    name: 'Master',
    //component: Master,
    component:(resolve)=>require(['../components/Master.vue'],resolve),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
       // component: Home,
        component:(resolve)=>require(['../view/Home.vue'],resolve),
        meta: {
          title: 'Home'
        }
      }
    ],
   

  },
  // 设备报表
  {
    path: '/master',
    name: 'Master',
    //component: Master,
    component:(resolve)=>require(['../components/Master.vue'],resolve),
    redirect: '/deviceReport',
    children: [
      {
        path: '/deviceReport',
        name: 'DeviceReport',
        //component: DeviceReport,
        component:(resolve)=>require(['../view/History.vue'],resolve),
        meta: {
          title: 'DeviceReport'
        }
    
      }

    ]

  },
  // 电站报表
  {
    path: '/master',
    name: 'Master',
   //component: Master,
   component:(resolve)=>require(['../components/Master.vue'],resolve),
    redirect: '/psReport',
    children: [
      {
        path: '/psReport',
        name: 'psReport',
        //component: PsReport,
        component:(resolve)=>require(['../view/PsChart.vue'],resolve),
        meta: {
          title: 'PsReport'
        }
      }

    ]

  },
  // 设备分析
  {
    path: '/master',
    name: 'Master',
    //component: Master,
    component:(resolve)=>require(['../components/Master.vue'],resolve),
    redirect: '/deviceAnalyse',
    children: [
      {
        path: '/deviceAnalyse',
        name: 'deviceAnalyse',
        //component: DeviceAnalyse,
        component:(resolve)=>require(['../view/DeviceChart.vue'],resolve),
        meta: {
          title: 'DeviceAnalyse'
        }
      }

    ]

  },
    // 报表
    {
      path: '/master',
      name: 'Master',
      //component: Master,
      component:(resolve)=>require(['../components/Master.vue'],resolve),
      redirect: '/report',
      children: [
        {
          path: '/report',
          name: 'report',
          //component: DeviceAnalyse,
          component:(resolve)=>require(['../view/Report.vue'],resolve),
          meta: {
            title: 'Report'
          }
        }
  
      ]
  
    },
  // 故障历史
  {
    path: '/master',
    name: 'Master',
    //component: Master,
    component:(resolve)=>require(['../components/Master.vue'],resolve),
    redirect: '/alarm',
    children: [
      {
        path: '/alarm',
        name: 'Alarm',
        //component: Alarm,
        component:(resolve)=>require(['../view/Alarm.vue'],resolve),
        meta: {
          title: 'Alarm'
        }
      }

    ]

  },
    // 电站信息
    {
      path: '/master',
      name: 'Master',
      //component: Master,
      component:(resolve)=>require(['../components/Master.vue'],resolve),
      redirect: '/psinfo',
      children: [
        {
          path: '/psinfo',
          name: 'PsInfo',
          //component: Alarm,
          component:(resolve)=>require(['../view/PsInfo.vue'],resolve),
          meta: {
            title: 'PsInfo'
          }
        }
  
      ]
  
    },
  // 权限管理
  {
    path: '/master',
    //component: Master,
    component:(resolve)=>require(['../components/Master.vue'],resolve),
    //component: Master,
    redirect: '/authority',
    children: [
      {
        path: '/authority',
        name: 'Authority',
        //component: Authority,
        component:(resolve)=>require(['../view/Authority.vue'],resolve),
        meta: {
          title: 'Authority'
        }
      }

    ]

  },
  // active
  {
    path: '/active',
    name: 'Active',
    //component: Active,
    component:(resolve)=>require(['../view/Active.vue'],resolve),
    meta: {
      title: 'Active'
    }
  },
  // 404
  {
    path: '/404',
    name: 'Page404',
    //component: Page404,
    component:(resolve)=>require(['../view/404.vue'],resolve),
    meta: {
      title: 'Page404'
    }
  },
  // timeout
  {
    path: '/timeout',
    name: 'Timeout',
    //component: Timeout,
    component:(resolve)=>require(['../view/TimeOut.vue'],resolve),
    meta: {
      title: 'Timeout'
    }
  }
]



const router = new VueRouter({
  mode: 'history',
  routes
})





const cookies = {
  // 设置cookie
  setCookie: function (c_name, value, expiremMinutes) {
    var exdate = new Date();
    exdate.setTime(exdate.getTime() + expiremMinutes * 60 * 1000);
    document.cookie = c_name + "=" + escape(value) + ((expiremMinutes == null) ? "" : ";expires=" + exdate.toGMTString());
  },

  // 读取cookie
  getCookie: function (c_name) {
    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + "=");
      if (c_start != -1) {
        c_start = c_start + c_name.length + 1;
        var c_end = document.cookie.indexOf(";", c_start);
        if (c_end == -1)
          c_end = document.cookie.length

        return unescape(document.cookie.substring(c_start, c_end))
      }
    }
    return ""
  },

  // 删除cookie
  delCookie: function (c_name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = this.getCookie(c_name);
    if (cval != null) {
      document.cookie = c_name + "=" + cval + ";expires=" + exp.toGMTString();
    }
  },
}

router.beforeEach((to, from, next) => {
  // to 将要访问路径
  // from 从哪个路径过来
  // next 放行函数
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
   
    let locale = cookies.getCookie("locale");
    // let titleName=[{
    //   "zh_CN":{
    //     "Login":"登录",
    //     "Home":"电站首页",
    //     "DeviceReport":"设备报表",
    //     "DeviceAnalyse":"设备数据分析",
    //     "PsReport":"电站报表",
    //     "Alarm":"报警历史",
    //     "Authority":"权限管理",
    //     "Active":"激活页面",
    //     "Page404":"页面404错误",
    //     "Timeout":"页面超时"
    //   },
    //   "en":{
    //     "Login":"Login",
    //     "Home":"PV Home",
    //     "DeviceReport":"Device Report",
    //     "DeviceAnalyse":"Device Analysis",
    //     "PsReport":"PV Report",
    //     "Alarm":"Alarm History",
    //     "Authority":"Role Set",
    //     "Active":"Active Page",
    //     "Page404":"Page404",
    //     "Timeout":"Timeout"
    //   },   
    //   "ja":{
    //     "Login":"ログイン",
    //     "Home":"発電所ホーム",
    //     "DeviceReport":"デバイスレポート",
    //     "DeviceAnalyse":"設備データ比較",
    //     "PsReport":"発電所レポート",
    //     "Alarm":"アラート履歴",
    //     "Authority":"発電所紐づけ",
    //     "Active":"アクティブなページ",
    //     "Page404":"404ページ",
    //     "Timeout":"タイムアウトページ"
    //   },
    // }]

    document.title = getTitle(locale,to.meta.title);
    // if(locale != undefined && locale != ""){
    //   document.title = titleName[0][locale][to.meta.title]
    // }else{
    //   document.title = titleName[0]["ja"][to.meta.title]
    // }
    
  }
  if (to.path === "/login") return next();
  if (to.path === "/active") return next();
  if (to.path === "/timeout") return next();
  let token = window.sessionStorage.getItem("token");




  if (token == null) {
    token = cookies.getCookie("token");
    let userId = cookies.getCookie("userId");
    window.sessionStorage.setItem("token", token);
    window.sessionStorage.setItem("userId", userId);
  }





  

  if (!(typeof (token) != "undefined" && token != null && token != "")) return next("/login");
  next();
})

export default router
