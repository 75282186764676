import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import defaultLocale from '../utils/locale'

Vue.use(VeeValidate, {
    events: 'blur', //events: 'blur|input'
    locale:defaultLocale,
    dictionary:dictionary
});
// 自定义validate
// 自定义validate
const dictionary = {
    zh_CN: {
        custom:{
            pmuSn:{
                required: () => '采集器SN不能为空',
                min:()=> "采集器SN不能小于15个字符",
                max: () => '采集器SN不能大于15个字符',
                pmusn: () => '请输入十或十五位采集序列号',
            },
            userName:{
                required: () => '用户ID不能为空',
                zzts:()=>'用户ID不允许特殊符号或全角字符',
            },
            userCode: {
                required: () => '用户ID不能为空',
                min:()=>"用户ID不能小于8个字符",
                max: () => '用户ID不能大于50个字符',
                zzts:()=>'用户ID不允许特殊符号或全角字符',
                repeatUser:()=>'用户ID已被使用'
            },
            userPassword:{
                required: () =>'密码不能为空',
                min:()=> "密码不能小于8个字符",
                //pwd:()=>'英文、数字，8-50字符内'
                pwd:()=>'密码包括至少1字母，1个数字'
            },
            repeatUserPassword:{
                required: () =>'确认密码不能为空',
                confirmed:()=> "两次密码不一致",
                
            },
            verifyKey:{
                required: () => '验证码不能为空',
            },
            password:{
                required: () => '密码不能为空',
                min:()=> "密码不能小于8个字符",
                max:()=> "密码不能大于50个字符",
                //pwd:()=>'英文、数字，8-50字符内'
                pwd:()=>'密码包括至少1个字母，1个数字'
            },
            resetPassword:{
                required: () => '确认密码不能为空',
                confirmed:()=> "两次密码不一致",
                
            },
            userEmail:{
                required: () => '邮箱不能为空',
                email:()=>'邮箱格式不正确',
                repeatEmail:()=>'邮箱已被使用'
            },
            repeatUserEmail:{
                required: () => '确认邮箱不能为空',
                confirmed:()=>"两次邮箱不一致",
                
            },
            verifyUserCode:{
                required: () => '用户ID不能为空',
                verifyUser: () => '用户ID不存在',
                
            },
            userTelephone: {
                telephone:()=>'只能数字与特殊字符(-#*+())输入'
            }
          }
    },
    ja: {
        custom:{
          pmuSn:{
              required: () => '必須項目',
              min:()=> "15文字必須",
              max: () => '15文字必須',
              pmusn: () => '10,15文字必須"',
          },
          userName:{
            required: () => '必須項目',
            zzts:()=>'特殊文字または全角文字は使用できません',
        },
          userCode: {
              required: () => '必須項目',
              min:()=>"8文字以上",
              max: () => '50文字以下',
              zzts:()=>'特殊文字または全角文字は使用できません',
              repeatUser:()=>'ユーザーIDが使用されています'
          },
          userPassword:{
              required: () =>'必須項目',
              min:()=> "8文字以上",
              max:()=> "50文字以下",
              //pwd:()=>'半角英数字、8-50文字'
              pwd:()=>'1文字と数字は必須です'
          },
          repeatUserPassword:{
              required: () =>'必須項目',
              confirmed:()=> "パスワードは一致していません",
              
          },
          verifyKey:{
              required: () => '必須項目',
          },
          password:{
              required: () => '必須項目',
              min:()=> "8文字以上",
              max:()=> "50文字以下",
              //pwd:()=>'半角英数字、8-50文字'
              pwd:()=>'1文字と数字は必須です'
          },
          resetPassword:{
              required: () => '必須項目',
              confirmed:()=> "パスワードは一致していません",
              
          },
          userEmail:{
              required: () => '必須項目',
              email:()=>'メールアドレスが一致しません',
              repeatEmail:()=>'メールが占有されています'
          },
          
          repeatUserEmail:{
              required: () => '必須項目',
              confirmed:()=>"パスワードは一致していません",
              
          },
          verifyUserCode:{
              required: () => '必須項目',
              verifyUser: () => 'ユーザIDが存在しない',
              
          },
        userTelephone: {
            telephone:()=>'数値と特殊文字(-#*+())'
        }
        }
      },
      en: {
        custom:{
            pmuSn:{
                required: () => 'Required Fields',
                min:()=> "SN can not be less than 15 characters",
                max: () => 'SN can not be more than 15 characters',
                pmusn: () => 'Please enter ten or fifteen SN',
            },
            userName:{
              required: () => 'Required Fields',
              zzts:()=>'UserID consists of numbers,letters',
          },
            userCode: {
                required: () => 'Required Fields',
                min:()=>"UserID can not be less than 8 characters",
                max: () => 'UserID can not be more than 50 characters',
                zzts:()=>'UserID consists of numbers,letters',
                repeatUser:()=>'UserID is occupied'
            },
            userPassword:{
                required: () =>'Required Fields',
                min:()=> "PWD can not be less than 8 characters",
                max:()=>'PWD can not be greater than 50 characters',
                pwd:()=>'PWD includes at least 1etter,1 number'
            },
            repeatUserPassword:{
                required: () =>"Required Fields",
                confirmed:()=> "PWD is different",
                
            },
            verifyKey:{
                required: () => 'Required Fields',
            },
            password:{
                required: () => 'Required Fields',
                min:()=> "PWD can not be less than 8 characters",
                //pwd:()=>'English letters,Numbers, 8-50 characters'
                pwd:()=>'PWD includes at least 1etter,1 number'
            },
            resetPassword:{
                required: () => 'Required Fields',
                confirmed:()=> "PWD is different",
                
            },
            userEmail:{
                required: () => 'Required Fields',
                email:()=>"It's wrong format",
                repeatEmail:()=>'E-mail is occupied'
            },
            
            repeatUserEmail:{
                required: () => 'Required Fields',
                confirmed:()=>"E-mail is different",
                
            },
            verifyUserCode:{
                required: () => 'Required Fields',
                verifyUser: () => 'UserID does not exist',
                
            },
            userTelephone: {
              telephone:()=>'Only number and (-#*+()) can be used'
          }
          }
      }
}
Validator.updateDictionary(dictionary)

// 1.用户名称
Validator.extend('zzts', {
    messages: {//不能是邮箱，全英文，半角，超过30个字符
        zh_CN: field => '不允许特殊符号或全角字符'
    },
    validate: value => {
        //return /^[a-zA-Z0-9_-]{6,50}$/.test(value);
        return /^[A-Za-z0-9]+$/.test(value);
       // return /^[\u4e00-\u9fa5A-Za-z0-9]*$/.test(value)
       //return /^[\u4E00-\u9FA5A-Za-z0-9_]+$/.test(value) 
       //[\u4E00-\u9FA5A-Za-z0-9]{2,20}
    }
})

// 2.邮箱
Validator.extend('email', {
    messages: {
        zh_CN: field => '邮箱格式不正确'
    },
    validate: value => {
        return /^[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?$/.test(value)
    }
})

// 3.密码验证
Validator.extend('pwd', {
    messages: {
        zh_CN: field => '登录密码格式不正确'
    },
    validate: value => {
        return /^(?![a-z]+$)(?![0-9]+$)[a-zA-Z0-9]{0,50}$/.test(value)

        //^(?![a-z]+$)(?![0-9]+$)[a-zA-Z0-9]{0,50}$
        //return /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/.test(value)
    }
})

// 4.用户名称重复
Validator.extend('repeatUser', {
    messages: {
        zh_CN: field => '已存在'
    },
     validate: async value => {
        const {data:ret}=await Vue.prototype.$http.get('/service-user/user/userCodeIsExist?userCode='+value,{});
        return ret.success;
    }
})
// 5.邮箱户名称重复
Validator.extend('repeatEmail', {
    messages: {
        zh_CN: field => '已存在'
    },
     validate: async value => {
        const {data:ret}=await Vue.prototype.$http.get('/service-user/user/userEmailIsExist?userEmail='+value,{});
        return ret.success;
    }
})
// 6.用户名称重复
Validator.extend('verifyUser', {
    messages: {
        zh_CN: field => '已存在'
    },
     validate: async value => {
        const {data:ret}=await Vue.prototype.$http.get('/service-user/user/userCodeIsExist?userCode='+value,{});
        return !ret.success;
    }
})


// 7.电话验证
Validator.extend('telephone', {
    messages: {
        zh_CN: field => '只能数字或小数与特殊字符(-#*+())输入'
    },
    validate: value => {
        return /^[0-9 \-#*+()]*$/.test(value)
    }
})


// 7.序列号验证
Validator.extend('pmusn', {
    messages: {
        zh_CN: field => '请输入十或十五位采集序列号'
    },
    validate: value => {
        if(value.length ==10 || value.length == 15){
            return true;
        }else{
            return false;
        }
    }
})


