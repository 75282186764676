import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueI18n from 'vue-i18n'
import store from './store/store'

import ECharts from 'echarts'
import './plugins/element.js'
import ElementLocale from 'element-ui/lib/locale'
//import enLocale from 'element-ui/lib/locale/lang/ja'


import macarons from 'echarts/theme/macarons'
// styles
import './assets/vendor/bootstrap/css/bootstrap.min.css'
//import './assets/vendor/font-awesome-4/css/font-awesome.min.css' //fontawesome 4 icons
import './assets/vendor/font-awesome-5/css/all.min.css' //fontawesome 5 icons

import './assets/vendor/animate-css/vivify.min.css'
import './assets/vendor/chartist/css/chartist.min.css'
import './assets/vendor/chartist-plugin-tooltip/chartist-plugin-tooltip.css'
import './assets/vendor/c3/c3.min.css'
import './assets/vendor/toastr/toastr.min.css'

import './assets/vendor/jquery-datatable/dataTables.bootstrap4.min.css' //talbe style
import './assets/vendor/jquery-datatable/fixedeader/dataTables.fixedcolumns.bootstrap4.min.css' //talbe style
import './assets/vendor/jquery-datatable/fixedeader/dataTables.fixedheader.bootstrap4.min.css' //talbe style

import './assets/vendor/sweetalert/sweetalert.css' //操作结果弹框
/* import './assets/vendor/dropify/css/dropify.min.css' //图片上传效果 */

import './assets/vendor/jquery-steps/jquery.steps.css' //分步操作效果

import './assets/vendor/bootstrap-datepicker/css/bootstrap-datepicker3.min.css' //日期选择器

import './assets/vendor/folder-menu/css/folder-menu.css' //首页6个设备风琴菜单效果

import './assets/vendor/tscrollbar/TScrollbar.css' //历史查询页面(history) 滚动设备列表


import './assets/css/SmartOM.min.css' //主样式


// JS
import './assets/bundles/libscripts.bundle.js'

import './assets/bundles/vendorscripts.bundle.js'

import './assets/bundles/template.js'

import './assets/bundles/charts/sparkline.js'

import './assets/bundles/pages/forms/dropify.js'


import './assets/bundles/datatablescripts.bundle.js' //talbe style
import './assets/vendor/jquery-datatable/buttons/dataTables.buttons.min.js' //table style
import './assets/bundles/pages/tables/jquery-datatable.js' //table style

import './assets/bundles/pages/ui/dialogs.js' //操作结果弹框
import './assets/vendor/sweetalert/sweetalert.min.js' //操作结果弹框

/* import './assets/vendor/dropify/js/dropify.js' //图片上传效果 */

import './assets/bundles/pages/forms/form-wizard.js' //分步操作效果
import './assets/vendor/jquery-steps/jquery.steps.js' //分步操作效果


import './assets/vendor/folder-menu/js/folder-menu.min.js' //首页6个设备风琴菜单效果
import './assets/vendor/folder-menu/js/folder-menu-function.js' //首页6个设备风琴菜单效果

import './assets/vendor/bootstrap-datepicker/js/bootstrap-datepicker.min.js' //日期选择器

import './assets/vendor/tscrollbar/TScrollbar.js' //历史查询页面（history） 滚动设备列表


import './assets/vendor/font-awesome-5/js/allSmartOM.js' //fontawesome5 icons  allSmartOM  vall.min

import './assets/vendor/bootstrap/js/bootstrap.min'


import './utils/vaildate'

// import HighchartsNoData from 'highcharts-no-data-to-display';
// HighchartsNoData(HighCharts)

// require('highcharts-no-data-to-display')(Highcharts)

import defaultLocale from './utils/locale'

import {delCookie} from "./utils/cookie"



import axios from 'axios'
// import { prototype } from 'core-js/core/dict'


axios.interceptors.request.use(config=>{
  config.headers.AuthorizationToken=window.sessionStorage.getItem("token");
  config.headers.AuthorizationUserId=window.sessionStorage.getItem("userId");
  return config;
},
function(error){
  return Promise.reject(error)
})


axios.interceptors.response.use(function (response) {
 
  let data=response.data;
  console.log(data.status)
  switch(data.status){
    case 505:
      
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("userId");
      window.sessionStorage.removeItem("selPsId");
      delCookie("token");
      delCookie("userId");
      window.location.href='/timeout';
      return null;
    break

  }
  // 对响应数据做点什么
  return response;
}, function (error) {
 
    if(typeof(error.response)!="undefined"){
      let data=error.response
      switch(data.status){
        case 505:
          //swal("会话失效请重新登录", "", "warning");
          swal({
            //提示的标题
            title: this.$t("interfaceMsg.505"),
            //提示的文本
            text: "",
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: this.$t("login.msg.determine") //'确定'
          });
          
          break
        case 405:
          //swal("请求方式错误", "请使用正确方式", "warning");
          swal({
            //提示的标题
            title: this.$t("interfaceMsg.405"),
            //提示的文本
            text: this.$t("login.msg.qsyzqfs"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: this.$t("login.msg.determine") //'确定'
          });
        break
        case 500:
          //swal("服务不可用", "请联系管理员", "warning");
          swal({
            //提示的标题
            title: this.$t("interfaceMsg.700"),
            //提示的文本
            text: this.$t("login.msg.qlxgly"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: this.$t("login.msg.determine") //'确定'
          });
        break
        default:
          //swal("服务不可用", "请联系管理员", "warning");
          swal({
            //提示的标题
            title: this.$t("interfaceMsg.700"),
            //提示的文本
            text: this.$t("login.msg.qlxgly"),
            //类型：警告，还有success,error和info
            type: "warning",
            //显示取消按钮
            showCancelButton: false,
            //确认按钮内文本
            confirmButtonText: this.$t("login.msg.determine") //'确定'
          });
        break
      }
      
      
    }
    else{
      if(error.message=="Network Error"){
        swal(this.$t("interfaceMsg.400"), this.$t("master.goBack"), "warning");
      }
    }

    return Promise.reject(error);
});
axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type']="application/json";
//axios.defaults.baseURL = 'https://test.smartom.com/api/'
// axios.defaults.baseURL = window.location.origin+"/api/";

let hostname=window.location.hostname;
//axios.defaults.baseURL = window.location.origin+'/api/'
axios.defaults.baseURL ='https://www.smartom.com/api/'
if(hostname=="localhost"){
  axios.defaults.baseURL = 'http://47.101.155.162/api/'
  //axios.defaults.baseURL = 'http://139.196.139.138/api/'
}
//axios.defaults.baseURL='http://192.168.3.158:7070/api/'
Vue.prototype.$http = axios;
Vue.config.productionTip = false
Vue.prototype.$echarts = ECharts
Vue.use(VueI18n)

import zh from './assets/languages/Chinese.json'
import ja from './assets/languages/Japanese.json'
import en from './assets/languages/English.json'

import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import enLocale from 'element-ui/lib/locale/lang/en'
import moment from "moment"
Vue.prototype.$moment = moment;
// 国际化
const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  messages: {
    'zh_CN': Object.assign(zh, zhLocale),  // 本地资源文件，我这里配2个语言，中文&英文，src下根据个人情况放置
    'ja': Object.assign(ja, jaLocale),
    'en': Object.assign(en, enLocale),
  },
  silentTranslationWarn: true
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')


